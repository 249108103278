import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import {queryRequestsAnalytics} from '../../queries';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import DatePicker from '../../components/Input/DatePicker';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {roundToXDigits} from '../../utils';

class RequestsAnalytics extends Component {
  state = {
    loading: true,
    error: false,
    items: [],
    avgReaction: '',
    dateFrom: null,
    dateTo: null,
    team: null,
  };

  componentDidMount() {
    const {selfUser} = this.props;

    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    this.load(selfUser.team, firstDay, lastDay);
  }

  load(team, dateFrom, dateTo) {
    this.setState({
      loading: true,
      dateFrom: dateFrom,
      dateTo: dateTo,
      team: team,
    });

    queryRequestsAnalytics({team, dateFrom, dateTo})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            items: resp.data.data.requestsAnalytics.items,
            avgReaction: resp.data.data.requestsAnalytics.avgReaction,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {
      onClose,
      open,
      classes,
      selfUser,
    } = this.props;

    const {loading, error, items, avgReaction, team, dateFrom, dateTo} = this.state;
    const isAdmin = hasRole(selfUser.role, userRoles.ADMIN);
    const emptyState = !(items && items.length > 0);
    let total = {
      offers: {arr: 0, avito: 0},
      viewed: {sell: 0, rent: 0},
      wait: {sell: 0, rent: 0},
      no: {sell: 0, rent: 0},
      cnt: {sell: 0, rent: 0},
    };

    return (
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>Аналитика заявок</DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialog}>
          <div className={classes.filter}>
            <Grid container spacing={8}>
              {
                isAdmin ? (
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth margin="none">
                      <Select
                        value={team}
                        input={<Input/>}
                        onChange={(e, node) => {
                          this.load(node.key, dateFrom, dateTo);
                        }}
                        className={classes.selectTeam}
                      >
                        {selfUser.teams.map(({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null
              }
              <Grid item md={isAdmin ? 4 : 6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <DatePicker
                    format="yyyy-MM-dd"
                    clearable
                    input={{
                      value: dateFrom,
                      onChange: value => {
                        this.load(team, value, dateTo);
                      },
                    }}
                    meta={{}}
                    label={'Дата начала периода'}
                  />
                </FormControl>
              </Grid>
              <Grid item md={isAdmin ? 4 : 6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <DatePicker
                    format="yyyy-MM-dd"
                    clearable
                    input={{
                      value: dateTo,
                      onChange: value => {
                        this.load(team, dateFrom, value);
                      },
                    }}
                    meta={{}}
                    label={'Дата конца периода'}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell rowSpan={2}><b>Сотрудник</b></TableCell>
                <TableCell
                  className={[classes.tableColBorderStart, classes.tableColBorderEnd].join(' ')}
                  colSpan={2}>
                  <b>Листинги</b>
                </TableCell>
                <TableCell
                  className={[classes.tableColBorderStart, classes.tableColBorderEnd].join(' ')}
                  colSpan={2}>
                  <b>Заявки</b>
                </TableCell>
                <TableCell
                  className={[classes.tableColBorderStart, classes.tableColBorderEnd].join(' ')}
                  rowSpan={2}
                >
                  <b>{'Среднее\nвремя\nреакции'}</b>
                </TableCell>
                <TableCell
                  className={[classes.textRed, classes.tableColBorderStart, classes.tableColBorderEnd].join(' ')}
                  colSpan={2}>
                  <b>{'Нет\nобратной\nсвязи'}</b>
                </TableCell>
                <TableCell
                  className={[classes.textBlueGray, classes.tableColBorderStart, classes.tableColBorderEnd].join(' ')}
                  colSpan={2}>
                  <b>{'Просмотр\nсостоялся'}</b>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableColBorderStart}><b>АРР</b></TableCell>
                <TableCell className={classes.tableColBorderEnd}><b>Авито</b></TableCell>
                <TableCell className={classes.tableColBorderStart}><b>Продажа</b></TableCell>
                <TableCell className={classes.tableColBorderEnd}><b>Аренда</b></TableCell>
                <TableCell className={classes.tableColBorderStart}><b>Продажа</b></TableCell>
                <TableCell className={classes.tableColBorderEnd}><b>Аренда</b></TableCell>
                <TableCell className={classes.tableColBorderStart}><b>Продажа</b></TableCell>
                <TableCell className={classes.tableColBorderEnd}><b>Аренда</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={loading || error || emptyState ? classes.progressWrapper : ''}>
              {
                loading ? (
                  <CircularProgress size={50} thickness={4} className={classes.progress}/>
                ) : (
                  error ? (<icons.ErrorOutline className={classes.progress}/>) : (
                    !emptyState ? (
                      <>
                        {
                          items.map((item, index) => {
                            ['sell', 'rent'].forEach(function (itemType) {
                              total.cnt[itemType] += item.total[itemType] || 0;
                              total.viewed[itemType] += item.viewed[itemType] || 0;
                              total.wait[itemType] += item.wait[itemType] || 0;
                              total.no[itemType] += item.no[itemType] || 0;
                            });
                            total.offers.arr += item.offers.arr;
                            total.offers.avito += item.offers.avito;
                            return (
                              <TableRow key={index} className={classes.tableRow}>
                                <TableCell>{item.member}</TableCell>
                                <TableCell className={classes.tableColBorderStart}>
                                  {roundToXDigits(item.offers.arr, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderEnd}>
                                  {roundToXDigits(item.offers.avito, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderStart}>
                                  {roundToXDigits(item.total.sell, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderEnd}>
                                  {roundToXDigits(item.total.rent, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderStart}>
                                  {item.avgReaction}
                                </TableCell>
                                <TableCell className={classes.tableColBorderStart}>
                                  {roundToXDigits(item.no.sell, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderEnd}>
                                  {roundToXDigits(item.no.rent, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderStart}>
                                  {roundToXDigits(item.viewed.sell, 1)}
                                </TableCell>
                                <TableCell className={classes.tableColBorderEnd}>
                                  {roundToXDigits(item.viewed.rent, 1)}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        }
                        <TableRow className={classes.tableRow}>
                          <TableCell><b>Всего</b></TableCell>
                          <TableCell className={classes.tableColBorderStart}>
                            <b>{roundToXDigits(total.offers.arr, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderEnd}>
                            <b>{roundToXDigits(total.offers.avito, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderStart}>
                            <b>{roundToXDigits(total.cnt.sell, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderEnd}>
                            <b>{roundToXDigits(total.cnt.rent, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderStart}>{avgReaction}</TableCell>
                          <TableCell className={classes.tableColBorderStart}>
                            <b>{roundToXDigits(total.no.sell, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderEnd}>
                            <b>{roundToXDigits(total.no.rent, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderStart}>
                            <b>{roundToXDigits(total.viewed.sell, 1)}</b>
                          </TableCell>
                          <TableCell className={classes.tableColBorderEnd}>
                            <b>{roundToXDigits(total.viewed.rent, 1)}</b>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <div className={classes.emptyState}>Нет данных</div>
                    )
                  )
                )
              }
            </TableBody>
          </Table>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            onClick={onClose}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  dialog: {
    padding: 0,
  },
  filter: {
    padding: '0 20px',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    '& > td, & > th': {
      // fontSize: '1rem',
      textAlign: 'center',
      verticalAlign: 'middle',
      whiteSpace: 'break-spaces',
    },
    '& > td:first-child': {
      textAlign: 'left',
    },
  },
  tableColBorderStart: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  tableColBorderEnd: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  textRed: {
    color: colors.red[700],
  },
  textYellow: {
    color: colors.yellow[700],
  },
  textBlueGray: {
    color: colors.blueGrey[700],
  },
  progressWrapper: {
    position: 'relative',
    height: 200,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  selectTeam: {
    marginTop: 32,
  },
  emptyState: {
    fontSize: '3rem',
    position: 'absolute',
    top: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: colors.grey[500],
  },
});

export default RequestsAnalytics = connect(
  state => ({
    selfUser: state.root.selfUser,
    teams: state.root.selfUser.teams,
  }), {}
)(withStyles(styles)(RequestsAnalytics));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import Popover from '@material-ui/core/Popover';

import FilterPressRelease from './form/FilterPressRelease';
import PressReleaseEdit from './PressReleaseEdit';
import base64url from '../../utils/base64url';
import {loadCloseDealPressRelease, loadEventPressRelease} from '../../actions';
import {getSection, setSection} from '../../utils/lastSection';

class PropertyList extends Component {
  state = {
    openAdd: false,
    openFilterDialog: false,
    _source: 'dealClose',
    filter: {
      searchQuery: '',
    },
    query: {},
    anchorEl: null,
    popoverContent: null,
    openSettings: false,
    city: [],
    _perPage: 30,
  };

  componentDidMount() {
    this.initialize(this.props.history.location);
  }


  initialize(location) {
    let filter = null;
    const query = location.search.substr(1);
    if (query.length > 0) {
      try {
        filter = JSON.parse(base64url.decode(query));
      } catch (error) {
      }
    } else {
      filter = {
        filter: {
          searchQuery: '',
        },
      };
    }
    if(!filter._source){
      let source = getSection('pressReleaseSource');
      if(source)
        filter._source = source;
    }
    this.setState({
      filter: filter.filter ? filter.filter : {searchQuery: ''},
      _source: filter._source || 'dealClose',
      _page: filter._page || 0,
    }, this.loadPressRelease);
  }

  loadPressRelease = () => {
    const {_source, _page} = this.state;
    let filter = {
      filter: this.state.filter,
      offset: _page * 30,
      limit: 30,
    };
    if(_source === 'dealClose')
      this.props.loadCloseDealPressRelease(filter);
    else {
      filter.section = _source;
      this.props.loadEventPressRelease(filter);
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.location !== this.props.location) {
      this.initialize(newProps.location);
    }
  }

  getChildrenWithProps = () => {
    const {
      history,
      dealClose,
      event,
      status,
    } = this.props;
    const {
      _source,
      _page,
    } = this.state;

    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        history: history,
        type: _source,
        dealCloseIds: dealClose && Object.keys(dealClose),
        dealClose,
        event,
        _page,
        totalCount: status.totalCount,
        eventIds: event && Object.keys(event),
        handleOnChangePage: (e, page) => this.handleOnChangePage(e, page),
      })
    );
  };

  render() {
    const {
      classes,
      history,
      status,
    } = this.props;

    const {
      openAdd,
      openFilterDialog,
      filter,
      anchorEl,
      popoverContent,
      _source,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row" key={0}>
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <Select
                value={_source}
                input={<Input/>}
                onChange={this.handleOnChangeSource}
              >
                <MenuItem value="videos">Видео посты</MenuItem>
                <MenuItem value="news">Последние новости</MenuItem>
                <MenuItem value="articles">Статьи</MenuItem>
                <MenuItem value="events">Предстоящие события</MenuItem>
                <MenuItem value="results">Итоги</MenuItem>
              </Select>
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={filter.searchQuery}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!filter.searchQuery}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              <IconButton
                className={classes.rightIcon}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={filter.searchQuery}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!filter.searchQuery}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {status.isLoad ? (
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
              ) : this.getChildrenWithProps()}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add/>
            </Fab>
          </div>
        </div>
        {openFilterDialog ? (
          <FilterPressRelease
            open={openFilterDialog}
            initialValues={filter}
            type={_source}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
        {openAdd ? (
          <PressReleaseEdit
            open={openAdd}
            type={_source}
            onSubmitted={data => {
              this.setState({openAdd: false});
              history.push(`/pressRelease/${_source}/${data.id}`);
            }}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  handleOnChangePage = (_, page) => {
    const {history, match} = this.props;
    const {_source, filter} = this.state;
    const qs = base64url.encode(JSON.stringify({_source, _page: page, filter}));
    history.push(match.url + '?' + qs);
  };

  handleOnChangeSource = event => {
    const {match, history} = this.props;
    this.setState({
      _source: event.target.value,
    });
    const qs = base64url.encode(JSON.stringify({_source: event.target.value, _page: 0}));
    history.push(match.url + '?' + qs);
    setSection('pressReleaseSource', event.target.value);
  };

  handleReset = () => {
    this.props.history.push(this.props.match.url);
    this.setState({searchInput: ''});
  };

  handleFilter = data => {
    const {_source} = this.state;
    this.props.history.push(this.props.match.url + '?' + base64url.encode(
      JSON.stringify({
        filter: data,
        _source: _source,
      }),

    ));
    this.setState({openFilterDialog: false});
  };

  handleSearchChange = e => {
    this.setState({
      filter: {
        ...this.state.filter,
        searchQuery: e.target.value,
      },
    });
  };

  handleSearchEnter = e => {
    if(e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const {match, history} = this.props;
    const path = match.url + '?'
      + base64url.encode(JSON.stringify({filter: this.state.filter}));
    history.push(path);
  };
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  mobilePagination: {
    marginRight: 16,
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
});

export default connect(
  () => ({}),
  {
    loadCloseDealPressRelease,
    loadEventPressRelease,
  }
)(withStyles(styles)(PropertyList));

import * as colors from '@material-ui/core/colors';

const defaultState = {
  priceTypes: [
    {value: 'sell', label: 'за объект', offerType: 'sell'},
    {value: 'sell_m2', label: 'за м²', offerType: 'sell'},
    {value: 'rent_per_month', label: 'за объект в месяц', offerType: 'rent'},
    {value: 'rent_m2_per_month', label: 'за м² в месяц', offerType: 'rent'},
  ],
  currencyCodes: [
    {code: 'RUB', label: '₽'},
    {code: 'USD', label: '$'},
    {code: 'EUR', label: '€'},
  ],
  areaUnits: [
    {value: 'm2', label: 'м²'},
    {value: 'a', label: 'ар (сотка)'},
    {value: 'ha', label: 'гектар'},
  ],
  pressReleaseContractTypes : [
    {value: 'exclusive', label: 'Эксклюзив'},
    {value: 'semi_exclusive', label: 'Полуэксклюзив'},
    {value: 'not_exclusive', label: 'Не эксклюзив'},
  ],
  pressReleaseBuyerServices : [
    {value: 'selection', label: 'Подбор помещений - базовый'},
    {value: 'evaluation', label: 'Оценка'},
    {value: 'short_list', label: 'Составление шорт-листа по подходящим объектам'},
    {value: 'consulting', label: 'Консультации'},
    {value: 'legal_support', label: 'Юридическое сопровождение сделки'},
    {value: 'tenant_support', label: 'Представление интересов арендатора перед собственником'},
  ],
  pressReleaseOwnerServices : [
    {value: 'realty_evaluation', label: 'Оценка недвижимости'},
    {value: 'market_analysis', label: 'Анализ рынка'},
    {value: 'consulting', label: 'Консультации'},
    {value: 'object_preparation', label: 'Подготовка объекта к реализации'},
    {value: 'realty_marketing', label: 'Маркетинг недвижимости'},
    {value: 'price_correction', label: 'Корректировка цены'},
    {value: 'negotiation', label: 'Проведение переговоров'},
    {value: 'owner_support', label: 'Представление интересов собственника перед арендатором'},
    {value: 'showing', label: 'Показы'},
    {value: 'legal_support', label: 'Юридическое сопровождение сделки'},
  ],
  pressReleaseDealTypes : [
    {value: 'rent', label: 'Аренда'},
    {value: 'sell', label: 'Продажа'},
    {value: 'sell_business', label: 'Продажа бизнеса'},
    {value: 'consulting', label: 'Консалтинг'},
  ],
  pressReleaseFeatures : [
    {value: 'quick_sell', label: 'Быстрая сделка'},
    {value: 'vacation_rent', label: 'Арендные каникулы'},
    {value: 'security_payment', label: 'Обеспечительный платеж'},
    {value: 'repair', label: 'Ремонт'},
    {value: 'long_rent', label: 'Долгосрочный договор с регистрацией'},
    {value: 'long_idle', label: 'Долгий простой'},
  ],
  propertyStatuses: [
    {value: 'new', label: 'Переговоры', color: colors.yellow},
    {value: 'opened', label: 'В работе', color: colors.green},
    {value: 'rejected', label: 'Отказ', color: colors.red},
    {value: 'closed', label: 'Реализован', color: colors.grey},
    {value: 'archived', label: 'Архивирован', color: colors.blueGrey},
  ],
  offerTypes: [
    {value: 'sell', label: 'Продажа'},
    {value: 'rent', label: 'Аренда'},
  ],
  offerStatuses: [
    {value: 'opened', label: 'В работе', color: colors.green},
    {value: 'closed', label: 'Реализован', color: colors.grey},
    {value: 'archived', label: 'Архивирован', color: colors.blueGrey},
  ],
  offerSources: [
    {value: 'broker', label: 'Взял брокер', short: 'Брокер'},
    {value: 'disposal', label: 'Взял диспозл', short: 'Диспозл'},
    {value: 'site', label: 'Заявка с сайта', short: 'Сайт'},
    {value: 'social', label: 'Заявка из соцсети', short: 'Соцсети'},
    {value: 'company', label: 'Другие источники', short: 'Другие'},
  ],
  offerArchiveReasons: [
    {value: 'owner', label: 'Реализовал собственник'},
    {value: 'we', label: 'Реализовали мы'},
    {value: 'refuse', label: 'Отказ от сотрудничества'},
  ],
  offerExports: [
    {value: 'cian', label: 'cian.ru'},
    {value: 'yandex', label: 'realty.yandex.ru'},
    {value: 'domclick', label: 'domclick.ru'},
  ],
  omskOfferExports: [
    {value: 'omsk.mlsn', label:'omsk.mlsn.ru'},
    // {value: 'omskrielt', label:'omskrielt.com'},
  ],
  offerExportsDisabled: [],
  propertyTypes: [],
  propertyPurposes: [],
  activities: [],
  activitiesDetails: [],
  buildingTypes: {
    'business_center': 'Бизнес-центр',
    'mall': 'Торговый центр',
    'administrative': 'Административное здание',
    'home': 'Жилой дом',
    'other': 'Другой',
  },
  netRenterTypes: [
    {value: 'tickets', label: 'Авиа и Ж/Д билеты'},
    {value: 'car_wash', label: 'Автомойка'},
    {value: 'car_service', label: 'Автосервис'},
    {value: 'car_dealer', label: 'Автосалон'},
    {value: 'alcomarket', label: 'Алкомаркеты и разливные напитки'},
    {value: 'pharmacy', label: 'Аптека'},
    {value: 'atelier', label: 'Ателье'},
    {value: 'bank', label: 'Банк'},
    {value: 'pool', label: 'Бассейн'},
    {value: 'bookmakers', label: 'Букмекерские конторы'},
    {value: 'beauty', label: 'Бьюти индустрия'},
    {value: 'museums', label: 'Выставки / Галереи / Музеи'},
    {value: 'hotel', label: 'Гостиница / Хостел'},
    {value: 'cargo', label: 'Грузоперевозки'},
    {value: 'children_center', label: 'Детский центр'},
    {value: 'land', label: 'Земельный участок'},
    {value: 'lab', label: 'Лаборатория'},
    {value: 'shop', label: 'Магазин'},
    {value: 'medicine', label: 'Медицина'},
    {value: 'music', label: 'Музыкальная студия'},
    {value: 'education', label: 'Образованием'},
    {value: 'catering', label: 'Общественное питание'},
    {value: 'delivery', label: 'Онлайн доставка товаров'},
    {value: 'office', label: 'Офис'},
    {value: 'bakery', label: 'Пекарня'},
    {value: 'food_production', label: 'Пищевое производство'},
    {value: 'production', label: 'Производство'},
    {value: 'entertainment', label: 'Развлекательная сфера'},
    {value: 'repair', label: 'Ремонтные работы'},
    {value: 'warehouse', label: 'Склад'},
    {value: 'sport', label: 'Спортивная секция'},
    {value: 'stretching', label: 'Студия растяжки'},
    {value: 'travel', label: 'Турагентство'},
    {value: 'photo', label: 'Фотостудия'},
    {value: 'cleaning', label: 'Химчистка'},
    {value: 'shoe_cleaning', label: 'Химчистка обуви'},
  ],
  dealSources: [
    {value: 'arrpro', label: 'arrpro.ru'},
    {value: 'avito', label: 'avito.ru'},
    {value: 'cian', label: 'cian.ru'},
    {value: 'yandex_realty', label: 'realty.yandex.ru'},
    {value: 'domclick', label: 'domclick.ru'},
    {value: 'current_client', label: 'текущий клиент'},
    {value: 'banner', label: 'баннер на объекте'},
    {value: 'landing', label: 'лендинг'},
    {value: 'recommendation', label: 'рекомендация'},
    {value: 'incoming_call', label: 'входящий звонок'},
    {value: 'outgoing_call', label: 'исходящий звонок'},
    {value: 'email', label: 'электронная почта'},
    {value: 'socials', label: 'соцсети'},
    {value: 'holding', label: 'сетевая компания'},
    {value: 'subagent', label: 'субагент'},
    {value: 'web_board', label: 'другая интернет площадка'},
    {value: 'other', label: 'другое'},
  ],
  dealStatuses: [
    {value: 'opened', label: 'Новая', color: colors.red},
    {value: 'accepted', label: 'Уточнён запрос', color: colors.orange},
    {value: 'with_offers', label: 'Предложены объекты', color: colors.yellow},
    {value: 'negotiation',label: 'Переговоры',color: colors.lightBlue},
    {value: 'finished', label: 'Закрыта нами', color: colors.green},
    {value: 'closed', label: 'Закрыта не нами', color: colors.blue},
    {value: 'paused', label: 'Приостановлена', color: colors.grey},
    {value: 'no_offers', label: 'Нет вариантов', color: colors.blueGrey},
    {value: 'bad', label: 'Некачественная', color: colors.brown},
  ],
  dealClosesStatuses: [
    'paused',
    'closed',
    'bad',
  ],
  dealClosesReason: {
    other_place: 'Снял в другом месте',
    personal_contact: 'Вышел на нашего собственника сам',
    outdated: 'Запрос стал не актуален',
  },
  offerDeclineReasons: [
    {value: 'price', label: 'Не устроила цена'},
    {value: 'terms', label: 'Не устроили условия'},
    {value: 'location', label: 'Не устроила локация'},
    {value: 'object', label: 'Не устроили технические параметры объекта'},
    {value: 'other', label: 'Другое'},
  ],
  dealOfferStatuses: [
    {value: 'new', label: 'Новый', color: colors.grey},
    {value: 'opened', label: 'Объект предложен', color: colors.red},
    {value: 'demonstrated', label: 'Выполнен показ', color: colors.orange},
    {value: 'terms', label: 'Согласование условий', color: colors.indigo},
    {value: 'negotiation', label: 'Подписание договора', color: colors.yellow},
    {value: 'agreed', label: 'Договор заключён', color: colors.lightBlue},
    {value: 'closed', label: 'Сделка закрыта', color: colors.lightGreen},
    {value: 'pause', label: 'На паузе', color: colors.blueGrey},
    {value: 'declined', label: 'Отказ от объекта', color: colors.brown},
  ],
  dealOfferActiveStatuses: [
    {value: 'terms', label: 'Согласование условий', color: colors.indigo},
    {value: 'negotiation', label: 'Подписание договора', color: colors.yellow},
    {value: 'agreed', label: 'Договор заключён', color: colors.lightBlue},
    {value: 'closed', label: 'Сделка закрыта', color: colors.lightGreen},
    {value: 'pause', label: 'На паузе', color: colors.blueGrey},
    {value: 'declined', label: 'Отказ от объекта', color: colors.brown},
  ],
  contactTypes: [
    {value: 'organization', label: 'Организация'},
    {value: 'entrepreneur', label: 'Индивидуальный предприниматель'},
    {value: 'person', label: 'Физическое лицо'},
  ],
  contactAttributeKinds: [
    {value: 'phone', label: 'Телефон'},
    {value: 'fax', label: 'Факс'},
    {value: 'email', label: 'Эл. почта'},
    {value: 'www', label: 'Веб-адрес'},
    {value: 'im', label: 'Мессенджер'},
    {value: 'address', label: 'Адрес'},
  ],
  contactRoles: [
    {value: 'renter', label: 'Арендатор'},
    {value: 'buyer', label: 'Покупатель'},
    {value: 'network', label: 'Сетевая компания'},
    {value: 'proprietor', label: 'Собственник'},
    {value: 'management', label: 'Управляющая компания'},
    {value: 'rival', label: 'Агентство недвижимости'},
    {value: 'investor', label: 'Инвестор'},
    {value: 'partner', label: 'Субагент, партнёр'},
    {value: 'developer', label: 'Девелопер'},
    {value: 'service', label: 'Сервисная организация'},
    {value: 'gr', label: 'Лоббист'},
    {value: 'official', label: 'Чиновник/госорган'},
    {value: 'soe', label: 'Госкомпания'},
  ],
  contactScopes: [
    {value: 'development', label: 'Девелопмент'},
    {value: 'it', label: 'IT и телекоммуникации'},
    {value: 'consulting', label: 'Консалтинг'},
    {value: 'investments', label: 'Инвестиции'},
    {value: 'retail', label: 'Ритейл'},
    {value: 'wholesale', label: 'Оптовая торговля'},
    {value: 'services', label: 'Услуги'},
    {value: 'catering', label: 'Общественное питание'},
    {value: 'manufacturing', label: 'Производство'},
    {value: 'government', label: 'Госсектор'},
    {value: 'non_profit', label: 'НКО'},
  ],
  teamSpecializations: [
    {value: 'retail', label: 'Торговая недвижимость'},
    {value: 'office', label: 'Офисная недвижимость'},
    {value: 'industrial', label: 'Промышленная недвижимость'},
    {value: 'selling', label: 'Продажа недвижимости'},
  ],
  userRoles: [
    {value: 'ROLE_ADMIN', label: 'Администратор системы'},
    {value: 'ROLE_TEAM_MANAGER', label: 'Руководитель команды'},
    {value: 'ROLE_TEAM_MEMBER', label: 'Участник команды'},
    {value: 'ROLE_CONTENT_MANAGER', label: 'Контент-менеджер'},
  ],
  entityTypes: [
    {value: 'Property', label: 'Объекты'},
    {value: 'Deal', label: 'Заявки'},
    {value: 'Offer', label: 'Листинги'},
    {value: 'Contact', label: 'Контакты'},
    {value: 'DealOffer', label: 'Сделки'},
    {value: 'Contract', label: 'Договоры'},
  ],
  entityLinks: [
    {
      type: 'Task',
      getLink: id => `/tasks/assigned/all#/tasks/${id}`,
      label: 'Задачи',
      preview: true,
    },
    {
      type: 'Deal',
      getLink: id => `/deals/${id}`,
      label: 'Заявки',
      preview: true,
    },
    {
      type: 'DealOffer',
      getLink: id => `/dealOffers/${id}`,
      label: 'Сделки',
      preview: true,
    },
    {
      type: 'Offer',
      getLink: id => `/offers/${id}`,
      label: 'Листинги',
      preview: false,
    },
    {
      type: 'SystemUpdate',
      getLink: id => `/news/${id}`,
      label: 'Системные обновления',
      preview: false,
    },
    {
      type: 'Contact',
      getLink: id => `/contacts/${id}`,
      label: 'Контакты',
      preview: false,
    },
  ],
  dealOfferDeclineReasons: [
    {value: 'price', label: 'Не устроила цена'},
    {value: 'terms', label: 'Не устроили условия'},
    {value: 'other', label: 'Новые обстоятельства препятствующие сделки'},
    {value: 'object', label: 'Покупатель нашел другой объект'},
    {value: 'buyer', label: 'Найден другой покупатель'},
  ],
  contractTypes: [
    {value: 'sell', label: 'Продажа'},
    {value: 'rent', label: 'Аренда'},
    {value: 'selection', label: 'Подбор'},
    {value: 'subagent', label: 'Субагентский договор'},
    {value: 'warranty', label: 'Гарантийное обязательство'},
    {value: 'amenities', label: 'Услуги'},
  ],
  floorFilter: [
    {value: '-1', label: 'Цоколь'},
    {value: '1', label: 'Первый'},
    {value: '2', label: 'Второй'},
    {value: '3', label: 'Третий и выше'},
  ],
  offerListColumns: [
    {value: 'shortAddress', label: 'Краткий адрес'},
    {value: 'district', label: 'Район'},
    {value: 'owner', label: 'Собственник'},
    {value: 'propertyType', label: 'Категория'},
    {value: 'offerType', label: 'Вид сделки'},
    {value: 'area', label: 'Площадь'},
    {value: 'priceForObj', label: 'Цена за объект'},
    {value: 'priceForM', label: 'Цена за м2'},
    {value: 'dates', label: 'Создан / Изменен'},
    {value: 'assignees', label: 'Ответственные'},
    {value: 'status', label: 'Статус'},
    {value: 'avitoReport', label: 'Авито'},
  ],
  propertyListColumns: [
    {value: 'shortAddress', label: 'Краткий адрес'},
    {value: 'district', label: 'Район'},
    {value: 'owner', label: 'Собственник'},
    {value: 'category', label: 'Категория'},
    {value: 'area', label: 'Площадь'},
    {value: 'status', label: 'Статус'},
    {value: 'assignees', label: 'Ответственные'},
    {value: 'dates', label: 'Создан / Изменен'},
  ],
  dealListColumns: [
    {value: 'offerType', label: 'Вид сделки'},
    {value: 'propertyPurpose', label: 'Категория'},
    {value: 'contact', label: 'Контакт'},
    {value: 'requirements', label: 'Требования'},
    {value: 'assignee', label: 'Ответственный'},
    {value: 'abcClass', label: 'Класс'},
  ],
  requestListColumns: [
    {value: 'source', label: 'Источник'},
    {value: 'activity', label: 'Сфера деятельности'},
    {value: 'area', label: 'Площадь'},
    {value: 'price', label: 'Бюджет'},
    {value: 'contact', label: 'Контакт'},
    {value: 'requirements', label: 'Доп. пожелания'},
    {value: 'offers', label: 'Предложенные листинги'},
    {value: 'status', label: 'Статус'},
    {value: 'assignee', label: 'Ответственный'},
    {value: 'exported', label: 'Выгрузка'},
  ],
  classificationDeal: [
    {value: 'A', label: 'A - Очень срочно (1 неделя), понятный запрос, высокая обратная связь.', selectLabel: 'A - Очень срочно (1 неделя)'},
    {value: 'B', label: 'B - Срочно (2-4 недели), понятный запрос, высокая обратная связь.', selectLabel: 'B - Срочно (2-4 недели)'},
    {value: 'C', label: 'С - Средняя срочность (4 недели и более), средняя обратная связь.', selectLabel: 'С - Средняя срочность (4 недели и более)'},
    {value: 'D', label: 'D - Низкая срочность (или срочности нет), низкая обратная связь.', selectLabel: 'D - Низкая срочность (или срочности нет)'},
  ],
  requestActions: [
    {value: 'not_suitable', label: 'Не подошло'},
    {value: 'work_in_progress', label: 'Ведется работа, думают'},
    {value: 'preparing', label: 'Подготовка к сделке'},
    {value: 'deal', label: 'Сделка'},
    {value: 'found', label: 'Нашел'},
    {value: 'looked', label: 'Посмотрели, думают'},
    {value: 'not_answer_phone', label: 'Не берет трубку'},
    {value: 'not_answer', label: 'Посмотрел, не выходит на связь'},
  ],
  requestResults: [
    {value: 'freely', label: 'Свободно'},
    {value: 'on_approval', label: 'На согласовании'},
    {value: 'not_arrive', label: 'Не приехал'},
    {value: 'realtor', label: 'Риелтор'},
  ],
  requestFeedback: [
    {value: 'yes', label: 'Обратная связь получена', color: colors.blue[700]},
    {value: 'wait', label: 'Отложенный показ', color: colors.yellow[700]},
    {value: 'no', label: 'Обратная связь не дана', color: colors.red[700]},
    {value: 'viewed', label: 'Просмотр состоялся', color: colors.green[700]},
  ],
  requestStatuses: [
    {value: 1, label: 'Актуально'},
    {value: 2, label: 'Не актуально'},
    {value: 3, label: 'Закрывается сделка'},
  ],
  avitoAdStatuses: [
    {value: 'active', label: 'Объявление активно'},
    {value: 'old', label: 'Срок размещения объявления истёк'},
    {value: 'blocked', label: 'Объявление заблокировано'},
    {value: 'rejected', label: 'Объявление отклонено для исправления нарушений'},
    {value: 'archived', label: 'Объявление находится в Архиве'},
    {value: 'removed', label: 'Объявление удалено навсегда'},
  ],
  avitoAdStatusesShort: [
    {value: 'active', label: 'Активно'},
    {value: 'old', label: 'Срок истёк'},
    {value: 'blocked', label: 'Заблокировано'},
    {value: 'rejected', label: 'Отклонено'},
    {value: 'archived', label: 'В архиве'},
    {value: 'removed', label: 'Удалено'},
  ],
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'LOAD_DEAL_SOURCES_CLASSIFICATION':
      return {
        ...state,
        dealSources: payload.dealSources,
      };
    case 'LOAD_PROPERTY_TYPES_CLASSIFICATION':
      return {
        ...state,
        propertyTypes: payload.propertyTypes,
      };
    case 'LOAD_PROPERTY_PURPOSES_CLASSIFICATION':
      return {
        ...state,
        propertyPurposes: payload.propertyPurposes,
      };
    case 'LOAD_REFERENCES':
      return {
        ...state,
        propertyTypes: payload.data.propertyTypes,
        propertyPurposes: payload.data.propertyPurposes,
        activities: payload.data.activities,
        activitiesDetails: payload.data.activitiesDetails,
      };
    default:
      return state;
  }
};

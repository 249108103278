import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Checkbox, Select} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';

import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import {getLabelsForValue} from '../../../utils';
import TeamSelect from '../../team/TeamSelect';
import DatePicker from '../../../components/Input/DatePicker';
import {
  queryCities,
  queryDistricts,
  querySubDistricts,
} from '../../../queries/CityQuery';
import {
  _onBlur,
  setFilterListItems,
  onClickItem,
  getListItems,
  clearFiled,
} from '../../../utils/filter';
import {
  setCityHint,
  setDistrictHint,
  setSubDistrictHint,
} from '../../../actions';

class FilterOffers extends Component {
  constructor(props){
    super(props);
    this.state = {
      listCity: [],
      selectedCity: false,
      cityName: '',
      hint: {
        fiasCityId: null,
        district: null,
      },
      district: [],
      selectedDistrict: false,
      listDistrict: [],
      subDistricts: [],
      selectedSubDistricts: false,
      listSubDistricts: [],
      isOpenListCity: false,
      isOpenListDistrict: false,
      isOpenListSubDistrict: false,
    };
    this.onBlur = _onBlur.bind(this);
    this.setFilterListItems = setFilterListItems.bind(this);
    this.onClickItem = onClickItem.bind(this);
    this.getListItems = getListItems.bind(this);
  }

  componentDidMount() {
    const {fiasCityId, cityName, districtName, district} = this.props.initialValues;
    this.initialValuesState();
    this.getListItems(queryCities, 'archived', 0).then(res => {
      const city = res.data.data.cities.items;
      this.setState({city}, () => {
        cityName && this.onClickItem('cityName', 'fiasCityId', {id: fiasCityId, name: cityName}, 'selectedCity', queryDistricts, 'city', 'district');
        districtName && this.onClickItem('districtName', 'district', {id: district, name: districtName}, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
      });
    });
  }

  initialValuesState = () => {
    const {fiasCityId, district, microDistrict} = this.props.initialValues;
    let state = {};
    if(fiasCityId) {
      state.hint = {fiasCityId};
      if(district) {
        state.hint = {...state.hint, district};
        if(microDistrict){
          state.hint = {...state.hint, microDistrict};
        }
      }
    }
    this.setState(state);
  };

  onChangeCity = e => {
    const {city} = this.state;
    let fieldsForClear = ['microDistrict', 'district', 'fiasCityId', 'districtName', 'microDistrictName'];
    clearFiled(fieldsForClear, this.props.change);
    this.setFilterListItems(
      e,
      'City',
      value => {
        this.setState({hint: {fiasCityId: value.id}});
        this.props.setCityHint(value);
        this.onClickItem('cityName', 'fiasCityId', value,'selectedCity', queryDistricts, 'city', 'district');
      },
      city,
      this.props.cityHint
    );
  };

 onChangeDistrict = e => {
   const {district, hint}=this.state;

   let fieldsForClear = ['microDistrict', 'district', 'microDistrictName'];
   clearFiled(fieldsForClear, this.props.change);

   let listHint = this.props.districtHint[hint.fiasCityId] ? Object.values(this.props.districtHint[hint.fiasCityId]): [];
   this.setFilterListItems(
     e,
     'District',
     value => {
       this.setState({hint: {...hint, district: value.id}});
       this.props.setDistrictHint({cityId: hint.fiasCityId, district: {id: value.id,name: value.name}});
       this.onClickItem('districtName', 'district', value, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
     },
     district,
     listHint,
   );
 };

  onChangeSubDistrict = e => {
    const {subDistricts, hint}=this.state;

    let fieldsForClear = ['microDistrict'];
    clearFiled(fieldsForClear, this.props.change);

    let listHint = this.props.subDistrictHint[hint.district] ? Object.values(this.props.subDistrictHint[hint.district]): [];

    this.setFilterListItems(
      e,
      'SubDistricts',
      value => {
        this.props.setSubDistrictHint({districtId: hint.district, subDistrict: {id: value.id, name: value.name}});
        this.onClickItem('microDistrictName', 'microDistrict', value, 'selectedSubDistrict');
      },
      subDistricts,
      listHint,
    );

  };

  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      onSubmit,
      open,
      offerStatuses,
      offerTypes,
      pristine,
      propertyTypes,
      propertyPurposes,
      teamMembers,
      floorFilter,
      classes,
      selfUser,
      avitoAdStatuses,
    } = this.props;

    const {
      listCity,
      selectedCity,
      cityName,
      hint,
      district,
      selectedDistrict,
      listDistrict,
      subDistricts,
      listSubDistricts,
      isOpenListCity,
      isOpenListDistrict,
      isOpenListSubDistricts,
    } = this.state;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Фраза для поиска"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Вид сделки</InputLabel>
            <Field name="type" component={Select}>
              <MenuItem value=""/>
              {offerTypes.map(({value, label}, i) => (
                <MenuItem value={value} key={i}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <Field
            name="propertyTypes"
            label="Категории"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyTypes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyTypes.map(({label, value}, i) => (
              <Variant value={value} label={label} key={i}/>
            ))}
          </Field>
          <Field name="fiasCityId" component={TextField} className={classes.searchInputHide} />
          <div className={classes.MenuItemWrapper}>
            <Field
              placeholder="Введите название города"
              fullWidth
              name="cityName"
              onBlur={() => this.onBlur('isOpenListCity')}
              component={TextField}
              label="Город"
              margin="normal"
              autoComplete="off"
              value={cityName}
              onFocus={e => {
                if(e.target.value === '')
                  this.setState({isOpenListCity: true, listCity: this.props.cityHint});
              }}
              onChange={this.onChangeCity}
            />
            {listCity.length>0 && !selectedCity && isOpenListCity
              ? <div className={classes.MenuItemContainer}>{ listCity.slice(0, 5).map((value, index) => (
                <MenuItem
                  fullWidth margin="normal"
                  className={classes.MenuItem}
                  onClick={
                    () => {
                      this.setState({hint: {fiasCityId: value.id}});
                      this.props.setCityHint(value);
                      this.onClickItem('cityName', 'fiasCityId', value,'selectedCity', queryDistricts, 'city', 'district');}} key={index}
                >
                  {value.name}
                </MenuItem>
              ))}</div>: null}

            {district.length > 0 && selectedCity ? (
              <div>
                <Field name="district" component={TextField} className={classes.searchInputHide} />
                <Field
                  placeholder="Введите название района"
                  fullWidth
                  name="districtName"
                  onBlur={() => this.onBlur('isOpenListDistrict')}
                  autoComplete="off"
                  component={TextField}
                  label="Район"
                  margin="normal"
                  onFocus={e => {
                    if(e.target.value === '')
                      this.setState({isOpenListDistrict: true, listDistrict: this.props.districtHint[hint.fiasCityId] ? Object.values(this.props.districtHint[hint.fiasCityId]) : []});
                  }}
                  onChange={this.onChangeDistrict}
                />

                {listDistrict.length > 0 && isOpenListDistrict
                  ? <div className={classes.MenuItemContainer}>{
                    listDistrict.slice(0, 5).map((value, index) => (
                      <MenuItem
                        onClick={() => {
                          this.setState({hint: {...hint, district: value.id}});
                          this.props.setDistrictHint({cityId: hint.fiasCityId, district: {id: value.id,name: value.name}});
                          this.onClickItem('districtName', 'district', value,'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
                        }}
                        key={index}
                      >
                        {value.name}
                      </MenuItem>

                    ))
                  }</div>: null}

                {subDistricts.length > 0 && selectedDistrict ? (
                  <div>
                    <Field name="microDistrict" component={TextField} className={classes.searchInputHide} />
                    <Field
                      placeholder="Введите название микрорайона"
                      fullWidth
                      name="microDistrictName"
                      onBlur={() => this.onBlur('isOpenListSubDistricts')}
                      autoComplete="off"
                      component={TextField}
                      label="Микрорайон"
                      margin="normal"
                      onFocus={e => {
                        if(e.target.value === '')
                          this.setState({
                            isOpenListSubDistricts: true,
                            listSubDistricts: this.props.subDistrictHint[hint.district] ? Object.values(this.props.subDistrictHint[hint.district]) : []});
                      }}
                      onChange={this.onChangeSubDistrict}
                    />
                    {listSubDistricts.length > 0 && isOpenListSubDistricts
                      ? <div className={classes.MenuItemContainer}>
                        { listSubDistricts.slice(0, 5).map((value, index) => (
                          <MenuItem
                            margin="normal"
                            onClick={() => {
                              this.onClickItem('microDistrictName', 'microDistrict', value, 'selectedSubDistrict');
                              this.props.setSubDistrictHint({districtId: hint.district, subDistrict: {id: value.id, name: value.name}});}} key={index}>
                            {value.name}
                          </MenuItem>
                        ))}</div>
                      : null}
                  </div>
                ) : null}
              </div>
            ): null}
          </div>
          <Field
            name="purposes"
            label="Назначение"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyPurposes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyPurposes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Field
            name="floor"
            label="Этаж"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(floorFilter, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {floorFilter.map(({label, value}, i) => (
              <Variant value={value} label={label} key={i} />
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="propertyAreaMin"
                  label="Площадь от"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="propertyAreaMax"
                  label="до (м²)"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field name="priceMinM2" label="Цена от" component={TextField} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field name="priceMaxM2" label="до (₽ за м2)" component={TextField} />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field name="priceMin" label="Цена от" component={TextField} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field name="priceMax" label="до (₽ за объект)" component={TextField} />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl margin="none">
            <FormControlLabel
              control={<Field name="priceHigh" component={Checkbox}/>}
              label="Цена завышена"
              required
            />
          </FormControl>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="paybackMin"
                  label="Окупаемость от"
                  component={TextField}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="paybackMax"
                  label="до (в годах)"
                  component={TextField}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="electricalPowerMin"
                  label="Мощность от"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="electricalPowerMax"
                  label="до (кВт)"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtFrom"
                  label="Создан с"
                  format={null}
                  component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtTo"
                  label="по"
                  format={null}
                  component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="statuses"
            label="Статусы"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(offerStatuses, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {offerStatuses.map(({label, value}, i) => (
              <Variant value={value} label={label} key={i} />
            ))}
          </Field>
          <FormControl margin="none">
            <FormControlLabel
              control={<Field name="private" component={Checkbox} normalize={value => (value ? 1 : 0)} />}
              label="Закрытые"
              required
            />
          </FormControl>
          <Field
            name="avitoStatuses"
            label="Статусы Авито"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(avitoAdStatuses, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {avitoAdStatuses.map(({label, value}, i) => (
              <Variant value={value} label={label} key={i} />
            ))}
          </Field>
          <FormControl fullWidth margin="normal">
            <Field
              name="avitoPublishDays"
              label="Дней до конца публикации"
              placeholder="Дней ДО И МЕНЕЕ до конца публикации"
              component={TextField}
            />
          </FormControl>
          <Field
            name="assignees"
            label="Ответственные"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(teamMembers, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {teamMembers.map(({id, name}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
          <Field
            name="team"
            label="Команда"
            component={TeamSelect}
            fullWidth
            margin="normal"
          />
          {
            selfUser && (selfUser.role === 'ROLE_ADMIN' || selfUser.team === 'a6562ca9-6b85-4600-a11c-b0b76327d0d8') ?
              <FormControl margin="none">
                <FormControlLabel
                  control={<Field name="top-n-lab" component={Checkbox}/>}
                  label="Импортирован из Top&Lab"
                  required
                />
              </FormControl>
              : null
          }
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = _theme => ({
  searchInput: {
    marginTop: 10,
  },
  searchInputHide: {
    marginTop: 10,
    display: 'none',
  },
  MenuItem: {
  },
  MenuItemContainer: {
    zIndex: 1,
    flexGrow: 1,
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
});
FilterOffers = connect(state => ({
  offerStatuses: state.root.classification.offerStatuses,
  offerTypes: state.root.classification.offerTypes,
  propertyTypes: state.root.classification.propertyTypes,
  floorFilter: state.root.classification.floorFilter,
  teamMembers: state.root.selfUser.teamMembers,
  selfUser: state.root.selfUser,
  propertyPurposes: state.root.classification.propertyPurposes,
  cityHint: Object.values(state.root.hint.city),
  districtHint: state.root.hint.district,
  subDistrictHint: state.root.hint.subDistrict,
  avitoAdStatuses: state.root.classification.avitoAdStatuses,
}),{
  setCityHint,
  setDistrictHint,
  setSubDistrictHint,
})(FilterOffers);

export default reduxForm({
  form: 'filter_offers',
  enableReinitialize: true,
})(withStyles(styles)(FilterOffers));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import PressReleaseDetails from './PressReleaseDetails';

class PressReleaseDetailsWrapper extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {pressRelease, id, type, onEdit, classes, history} = this.props;
    const {menuAnchor} = this.state;
    let pressReleaseName = pressRelease.name;
    if (type === 'videos') {
      pressReleaseName = 'Видео пост';
    } else if (type === 'results') {
      pressReleaseName = 'Итоги';
    }
    return (
      <Paper elevation={1} square className="column">
        <CardHeader
          subheader={pressReleaseName}
          action={
            <IconButton
              classes={{root: classes.icon}}
              onClick={({currentTarget}) => {
                this.setState({menuAnchor: currentTarget});
              }}
            >
              <icons.MoreVert/>
            </IconButton>
          }
        />
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClick={() => {
            this.setState({menuAnchor: null});
          }}
        >
          <MenuItem onClick={() => onEdit()}>
            Редактировать
          </MenuItem>
        </Menu>
        <Divider/>
        <PressReleaseDetails
          pressRelease={pressRelease}
          id={id}
          history={history}
          type={type}
        />
      </Paper>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
});

export default connect()(withStyles(styles)(PressReleaseDetailsWrapper));

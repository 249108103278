import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import DatePicker from '../../../components/Input/DatePicker';
import TextField from '../../../components/Input/TextField';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';
import Photos from './Photos';

class ArticlesForm extends Component {
  render() {
    const {
      array: {push, remove, swap},
      array,
      photos,
    } = this.props;

    return (
      <React.Fragment>
        <Field
          name="date"
          label="Дата"
          required
          format={null}
          component={props => (
            <DatePicker format="yyyy-MM-dd" clearable {...props} />
          )}
          fullWidth
          rowsMax={15}
          margin="normal"
        />
        <Field
          name="name"
          label="Название"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="description"
          label="Описание"
          required
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={50000}
          fullWidth
          min={200}
          margin="normal"
        />
        <Field
          name="comment"
          label="Комментарий"
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={1000}
          fullWidth
          margin="normal"
        />
        <Photos
          name="photos"
          label="Фотографии (не обязательно, не более 6)"
          array={array}
          photos={photos}
          push={(name, value) => push(name, value)}
          remove={(name, value) => remove(name, value)}
          swap={(name, oldIndex, newIndex) => swap(name, oldIndex, newIndex)}
          isOptional={true}
          isMultiple={true}
        />
      </React.Fragment>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(null, {})(withStyles(styles)(ArticlesForm));

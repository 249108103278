import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {Checkbox} from 'redux-form-material-ui';
import PropertyFields from '../../property/form/PropertyFields';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';
import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import {createAreaFormatter, getLabelsForValue, mapLabels} from '../../../utils';

class Form extends Component {
  render() {
    const {
      address,
      array,
      change,
      offerExports,
      offerStatuses,
      offerSources,
      offerArchiveReasons,
      offerType,
      offerStatus,
      priceTypes,
      propertyType,
      allOfferExports,
      onTypeChange,
      onStatusChange,
      offerExportsDisabled,
      selfUser,
    } = this.props;

    return (
      <FormSection name="offer">
        <Field
          name="source"
          label="Источник"
          required
          component={TextField}
          select
          fullWidth
          margin="normal"
        >
          {offerSources.map(({value, label}, i) => (
            <MenuItem value={value} key={i}>{label}</MenuItem>
          ))}
        </Field>
        <Field
          name="status"
          label="Статус"
          required
          component={TextField}
          select
          fullWidth
          margin="normal"
          onChange={e => {
            onStatusChange(e.target.value);
          }}
        >
          {offerStatuses.map(({value, label}, i) => (
            <MenuItem key={i} value={value}>{label}</MenuItem>
          ))}
        </Field>
        {
          offerStatus === 'archived' ? (
            <Field
              name="archiveReason"
              label="Причина архивации"
              required
              component={TextField}
              select
              fullWidth
              margin="normal"
            >
              {offerArchiveReasons.map(({value, label}, i) => (
                <MenuItem key={i} value={value}>{label}</MenuItem>
              ))}
            </Field>
          ) : null
        }
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="private" component={Checkbox}/>}
            label="Закрытый (не выгружается на площадки)"
            required
          />
        </FormControl>
        <Field
          name="type"
          label="Вид сделки"
          required
          component={TextField}
          onChange={e => {
            change('offer.name', this.generateName({offerType: e.target.value}));
            onTypeChange(e.target.value);
          }}
          select
          fullWidth
          margin="normal"
        >
          <MenuItem value="sell">Продажа</MenuItem>
          <MenuItem value="rent">Аренда</MenuItem>
        </Field>
        {offerType ? (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Field
                name="price.value.amount"
                label="Цена"
                required
                component={TextField}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="price.value.currency"
                label="Валюта"
                required
                component={TextField}
                select
                fullWidth
                margin="normal"
              >
                <MenuItem value="RUB">₽</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field
                name="price.type"
                label="Тип цены"
                required
                component={TextField}
                select
                fullWidth
                margin="normal"
              >
                {priceTypes
                  .filter(type => offerType === type.offerType)
                  .map(({value, label}, i) => (
                    <MenuItem value={value} key={i}>{label}</MenuItem>
                  ))}
              </Field>
            </Grid>
            {
              offerType === 'sell' ? (
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="sellRentRights" component={Checkbox}/>}
                    label="Переуступка права аренды"
                    required
                  />
                </FormControl>
              ) : (
                <>
                  <FormControl margin="none">
                    <FormControlLabel
                      control={<Field name="subRent" component={Checkbox}/>}
                      label="Субаренда"
                      required
                    />
                  </FormControl>
                  <FormControl margin="none">
                    <FormControlLabel
                      control={<Field name="withDeposit" component={Checkbox}/>}
                      label="С залогом"
                      required
                    />
                  </FormControl>
                </>
              )
            }
            <FormControl margin="none">
              <FormControlLabel
                control={<Field name="priceHigh" component={Checkbox}/>}
                label="Цена завышена"
                required
              />
            </FormControl>
            <Grid item xs={12}>
              <Field
                name="revenue"
                label="Комиссия от сделки, %"
                required
                component={TextField}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        ) : null}
        <FormSection name="property">
          <PropertyFields
            readonlyAddress={true}
            address={address}
            array={{
              push: (name, value) => array.push(`offer.${name}`, value),
              remove: (name, index) => array.remove(`offer.${name}`, index),
              move: (name, oldIndex, newIndex) => array.move(`offer.${name}`, oldIndex, newIndex),
            }}
            change={(name, value) => change(`offer.${name}`, value)}
            onAreaAmountChange={e => {
              change('offer.name', this.generateName({areaAmount: e.target.value}));
            }}
            onAreaUnitChange={e => {
              change('offer.name', this.generateName({areaUnit: e.target.value}));
            }}
            onTypeChange={e => {
              const value = e.target.value;

              change('offer.name', this.generateName({propertyType: value}));

              if(value && value !== 'land'){
                change(`offer.property.type.attributes.${value}.condition`, 'normal');
                change(`offer.property.type.attributes.${value}.entrance`, 'street');
                change(`offer.property.type.attributes.${value}.parking`, false);
              }
            }}
            propertyType={propertyType}
            hideDescription={true}
          />
        </FormSection>
        <Field
          name="name"
          label="Название"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="prologue"
          label="Заголовок"
          component={TextField}
          multiline
          rowsMax={10}
          fullWidth
          margin="normal"
        />
        <Field
          name="property.description"
          label="Описание"
          multiline
          rowsMax={10}
          component={TextFieldCount}
          width="100%"

          min={100}
          fullWidth
          margin="normal"
        />
        <Field
          name="epilogue"
          label="Подпись"
          component={TextField}
          multiline
          rowsMax={10}
          fullWidth
          margin="normal"
        />
        <FormControl margin="normal">
          <FormControlLabel
            control={
              <Field name="semantics" component={Checkbox} />
            }
            label="Разместить с семантикой"
          />
        </FormControl>
        <FormControl margin="normal">
          <FormControlLabel
            control={
              <Field name="teamEpilogue" component={Checkbox} />
            }
            label="Использовать подпись команды"
          />
        </FormControl>
        <Field
          name="ignoreExports"
          label="Не выгружать на площадки"
          component={TextField}
          select
          SelectProps={{
            multiple: true,
            renderValue: value => getLabelsForValue(allOfferExports, value).join(', '),
          }}
          format={value => value || []}
          fullWidth
          margin="normal"
        >
          <Variant disabled={selfUser.role === 'ROLE_TEAM_MEMBER'} value={'avito'} label={'avito.ru'}/>
          {offerExports.map(({label, value}, i) =>
            <Variant
              disabled={value === 'cian' && selfUser.role === 'ROLE_TEAM_MEMBER'}
              value={value}
              label={label}
              key={i}/>
          )}
          {offerExportsDisabled.map(({label, value}, i) =>
            <Variant disabled={true} value={value} label={label} key={i}/>
          )}
        </Field>
        <Field
          name="comment"
          label="Комментарий"
          component={TextField}
          multiline
          rowsMax={15}
          fullWidth
          margin="normal"
        />
      </FormSection>
    );
  }

  generateName(attributes) {
    const {formatArea, propertyTypes} = this.props;

    const offerType = attributes.offerType || this.props.offerType;
    const propertyArea = this.props.propertyArea || {};
    if (attributes.areaAmount) {
      propertyArea.amount = attributes.areaAmount;
    }
    if (attributes.areaUnit) {
      propertyArea.unit = attributes.areaUnit;
    }
    const propertyType = attributes.propertyType || this.props.propertyType;

    let name = {'sell': 'Продам', 'rent': 'Сдам'}[offerType] || '';
    name += ' ' + (propertyTypes[propertyType] || '').toLowerCase();
    name += propertyArea ? (', ' + formatArea(propertyArea)) : '';

    return name;
  }
}

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerExports: state.root.classification.offerExports,
    offerExportsDisabled: state.root.classification.offerExportsDisabled,
    allOfferExports: [
      ...state.root.classification.offerExports,
      ...state.root.classification.offerExportsDisabled, {
        value: 'avito',
        label: 'avito.ru',
      },
    ],
    offerStatuses: state.root.classification.offerStatuses,
    offerSources: state.root.classification.offerSources,
    offerArchiveReasons: state.root.classification.offerArchiveReasons,
    priceTypes: state.root.classification.priceTypes,
    propertyTypes: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    selfUser: state.root.selfUser,
  })
)(Form);
